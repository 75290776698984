import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebaseConfig"; // Upewnij się, że masz poprawnie skonfigurowane firebaseConfig
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null); // Resetuj błędy przed próbą logowania

    try {
      // Logowanie za pomocą Firebase Auth
      await signInWithEmailAndPassword(auth, email, password);
      console.log("Logged in successfully");
      navigate("/"); // Przekierowanie na stronę główną po zalogowaniu
    } catch (err) {
      console.error("Login error:", err.message);
      setError("Invalid email or password. Please try again."); // Ustaw komunikat o błędzie
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h1 style={styles.title}>Log in</h1>
        <form onSubmit={handleLogin} style={styles.form}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={styles.input}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
            required
          />
          <button type="submit" style={styles.button}>
            Log in
          </button>
        </form>
        {error && <p style={styles.error}>{error}</p>} {/* Komunikat o błędzie */}
        <div style={styles.links}>
          <a href="/forgot-password" style={styles.link}>
            Forgot your password?
          </a>
          <a href="/sign-up" style={styles.link}>
            Don’t have an account? Sign up
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "rgb(250, 250, 251)",
  },
  card: {
    width: "400px",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontSize: "24px",
    marginBottom: "20px",
    textAlign: "center",
    color: "#000",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginBottom: "10px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #000",
    backgroundColor: "#fff",
    color: "#000",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#000",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  error: {
    color: "red",
    marginTop: "10px",
    textAlign: "center",
  },
  links: {
    marginTop: "10px",
    textAlign: "center",
  },
  link: {
    display: "block",
    color: "#000",
    textDecoration: "none",
    marginBottom: "5px",
  },
};

export default Login;
