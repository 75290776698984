import React from 'react';
import './Navbar.css'; // Importujemy plik ze stylami

function Navbar({ activeTab, setActiveTab }) {
  return (
    <nav className="navbar">
      <h2 className="logo">xTemplater</h2>
      <div className="links">
        <button
          onClick={() => setActiveTab('templater')}
          className={`nav-button ${activeTab === 'templater' ? 'active' : ''}`}
        >
          Templater
        </button>
        <button
          onClick={() => setActiveTab('interpreter')}
          className={`nav-button ${activeTab === 'interpreter' ? 'active' : ''}`}
        >
          Interpreter
        </button>
        <button
          onClick={() => setActiveTab('documents')}
          className={`nav-button ${activeTab === 'documents' ? 'active' : ''}`}
        >
          My Documents
        </button>
        <button
          onClick={() => setActiveTab('govMate')}
          className={`nav-button ${activeTab === 'govmate' ? 'active' : ''}`}
        >
          Community
        </button>
        {/* <button
          onClick={() => setActiveTab('govMate')}
          className={`nav-button ${activeTab === 'govmate' ? 'active' : ''}`}
        >
          GovMate (Ai)
        </button> */}
       
        <button className="nav-button">Help</button>
      </div>
    </nav>
  );
}

export default Navbar;
