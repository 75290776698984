import React from 'react';
import Select from 'react-select';

function Form({
  fileData,
  formValues,
  handleInputChange,
  languageOptions,
  selectedLanguage,
  setSelectedLanguage,
  currentSectionIndex,
  goToPreviousSection,
  goToNextSection,
  isFormComplete,
  handleGenerate,
  setIsModalOpen,
}) {

    
  const renderField = (field) => {
    const languageData = field.languages.find(
      (lang) => lang.language === selectedLanguage.value
    ) || {};
    
    const { inputType, name } = field;
    const { fieldName, placeholder, hint } = languageData;

    return (
      <div key={name} style={styles.fieldContainer}>
        <label htmlFor={name} style={styles.label}>
          {fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}
        </label>
        <input
          type={inputType === 'text' ? 'text' : inputType}
          id={name}
          name={name}
          placeholder={placeholder}
          style={styles.input}
          value={formValues[name] || ""}
          onChange={(e) => handleInputChange(name, e.target.value)}
        />
        {hint && <small style={styles.hint}>{hint}</small>}
      </div>
    );
  };

  return (
    <div style={styles.container}>
     

 

      <div style={styles.sectionContent}>
      <div style={styles.sectionHeadingContainer}>
    {fileData[currentSectionIndex].sectionNames.find(
      (name) => name[selectedLanguage.value]
    )?.[selectedLanguage.value] || "Section"}
  </div>

        {fileData[currentSectionIndex].variables.map(renderField)}

        <div style={{
  ...styles.navigationButtons,
  justifyContent: currentSectionIndex < fileData.length - 1 && currentSectionIndex > 0 ? 'space-between' : 'flex-end'
}}>
  {currentSectionIndex > 0 && (
    <button
      style={styles.navButton}
      onClick={goToPreviousSection}
    >
      Previous Section
    </button>
  )}
  {currentSectionIndex < fileData.length - 1 ? (
    <button style={styles.navButton} onClick={goToNextSection}>
      Next Section
    </button>
  ) : (
    <button
      onClick={() => setIsModalOpen(true)}
      style={{
        ...styles.navButton,
        backgroundColor: isFormComplete() ? 'green' : 'gray',
      }}
      disabled={!isFormComplete()}
    >
      Generate Document
    </button>
  )}
</div>

      </div>
    </div>
  );
}

const styles = {
    container: {
        padding: '20px',
        maxWidth: '600px',
        margin: 'auto',
        backgroundColor: '#fff',
        border: '1px solid #ccc', // Light gray border around the form
        borderRadius: '8px',
        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.05)', // Lighter shadow
        paddingBottom: '2rem',
        position: 'relative', // Allows the section heading box to overlap
        paddingTop: '40px', // Adds space for the section heading container
        marginTop: '5rem',
      },
 sectionHeadingContainer: {
    position: 'absolute',
    top: '-20px',
    left: '-1px',
    backgroundColor: '#333',
    color: '#fff',
    padding: '10px 15px',
    borderRadius: '4px',
    fontWeight: 'bold',
    fontSize: '.9rem',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  sectionContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.5rem', // Adds space after the section title box
  },
  languageSelectorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  label: {
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '5px',
  },
  separator: {
    marginTop: '2rem',
    marginBottom: '2rem',
    border: 'none',
    borderTop: '2px solid #333',
    opacity: '0.7',
  },
  sectionHeading: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '1rem',
  },
  sectionContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
  },
  input: {
    padding: '10px',
    border: '1px solid #333',
    borderRadius: '4px',
    fontSize: '1em',
    marginTop: '5px',
  },
  hint: {
    color: '#666',
    fontSize: '0.9em',
    marginTop: '5px',
  },
  navigationButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    gap: '10px', // Adds space between buttons
  },
  navButton: {
    padding: '12px 24px',
    backgroundColor: '#333',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
    minWidth: '120px', // Sets a comfortable minimum width
    textAlign: 'center',
  },
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #333',
    borderRadius: '4px',
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'rgb(240, 240, 240)' : 'white',
    color: '#333',
  }),
};

export default Form;
