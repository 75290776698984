import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { ref, uploadBytesResumable, getDownloadURL, listAll } from "firebase/storage";
import { useTranslation } from 'react-i18next';
import { storage } from './firebaseConfig'; // Import your storage from firebaseConfig
import ChatbotInterface from './ChatbotInterface';
import Form from './components/documentInterpreter/Form';

import Modal from 'react-modal'; // Instal

function DocumentInterpreter() {

  const [file, setFile] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isUploadCollapsed, setIsUploadCollapsed] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState('');
  const [downloadLinks, setDownloadLinks] = useState({ docx: '', html: '', pdf: '' });

  const [filePath, setFilePath] = useState('');


const [retainVariables, setRetainVariables] = useState(false); // Track retain-variables option

  const [additionalData, setAdditionalData] = useState();

  const [activeTab, setActiveTab] = useState('existing');
  const [localState ,setLocalState] = useState({});
  const [radioValues, setRadioValues] = useState({});
  const [formState, setFormState] = useState({});
  const [activeSectionIndex, setActiveSectionIndex] = useState(0); // Track the active section
  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [inputMode, setInputMode] = useState('form'); // 'form' or 'chatbot'
  const [formValues, setFormValues] = useState({});
  const [expandedSections, setExpandedSections] = useState({
    aiInstructions: false,
    tags: false,
  });
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const averageTimePerField = 10; // average time in seconds per field
  const totalFields = fileData ? fileData.reduce((count, section) => count + section.variables.length, 0) : 0;
  const estimatedTotalTime = totalFields * averageTimePerField; // total time in seconds
  const [completedFields, setCompletedFields] = useState(0);
  const { t, i18n } = useTranslation();

  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');

  const progressPercentage = (completedFields / totalFields) * 100;
  const estimatedRemainingTime = estimatedTotalTime - completedFields * averageTimePerField;

  const [dragActive, setDragActive] = useState(false);

  // State for tracking upload progress
const [uploadProgress, setUploadProgress] = useState(0);

// State for managing selected languages
const [selectedLanguages, setSelectedLanguages] = useState([]);

console.log(fileData)

const handleCheckboxChange = (e, format) => {
  if (e.target.checked) {
    setSelectedFormat((prev) => [...prev, format]);
  } else {
    setSelectedFormat((prev) => prev.filter((item) => item !== format));
  }
};

// Function to handle successful upload
const onUploadSuccess = (downloadUrl) => {
  // You can customize this function based on your requirements
  alert(`Plik został skopiowany! Link do pobrania: ${downloadUrl}`);
};


  useEffect(() => {
    const fetchUploadedDocuments = async () => {
      try {
        const listRef = ref(storage, 'uploads');
        const res = await listAll(listRef);
        const documentOptions = res.items.map((itemRef) => ({
          value: itemRef.fullPath,
          label: itemRef.name,
        }));
        setUploadedDocuments(documentOptions);
      } catch (error) {
        console.error('Error fetching uploaded documents:', error);
      }
    };
    fetchUploadedDocuments();
  }, []);

  useEffect(() => {
    if (fileData && fileData.length > 0) {
      const firstSection = fileData[0];
      const languages = firstSection.sectionNames.map((langObj) => {
        const [key] = Object.keys(langObj);
        return { value: key, label: key.toUpperCase() };
      });

      setLanguageOptions(languages);
      setSelectedLanguage(languages[1] || null); // Default to the first language or null if none
    }
  }, [fileData]);

  useEffect(() => {
    // Initialize section expanded state and formValues with empty values
    if (fileData) {
      const initialExpandedState = {};
      const initialFormValues = {};
      fileData.forEach((section, sectionIndex) => {
        initialExpandedState[sectionIndex] = true; // Default: all sections expanded
        section.variables.forEach((variable) => {
          initialFormValues[variable.name] = ""; // Empty values initially
        });
      });
      setExpandedSections(initialExpandedState);
      setFormValues(initialFormValues);
    }
  }, [fileData]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setSelectedDocument(null);
    }
  };


  const handleGenerate = async () => {
  
  
    try {
      // Przygotowanie danych do wysłania
  
      // Wywołanie backendowej funkcji generującej dokument
      const response = await axios.post(
        // 'http://127.0.0.1:5001/xtemplater-web-app/us-central1/processFormAndGenerateDocx',
        'https://us-central1-xtemplater-web-app.cloudfunctions.net/processFormAndGenerateDocx',
        {
          filePath: filePath,  
          formValues, // Include formValues as part of the payload
        },
        { headers: { 'Content-Type': 'application/json' } }
    );
    
  
      const { fileUrl } = response.data;
  
      console.log('URL do wygenerowanego dokumentu:', fileUrl);
  
      // Ustawienie URL w stanie, aby umożliwić pobranie
      setDownloadLinks(prev => ({ ...prev, docx: fileUrl }));
  
      // Zamknięcie modalu
      setIsModalOpen(false);
  
      // Wyświetlenie powiadomienia
      setMessage('Dokument został wygenerowany pomyślnie!');
      setMessageColor('green');
    } catch (error) {
      console.error('Błąd podczas generowania dokumentu:', error);
      setMessage(`Błąd podczas generowania dokumentu: ${error.response?.data || error.message}`);
      setMessageColor('red');
      setIsModalOpen(false);
    }
  };
  

  const handleDownload = () => {
    if (selectedFormat.length === 0) return;
  
    try {
      // Loop through the selected formats and trigger downloads
      selectedFormat.forEach((format) => {
        const url = downloadLinks[format];
        if (url) {
          const link = document.createElement('a');
          link.href = url;
          link.download = `output.${format}`;
          link.click();
        }
      });
    } catch (error) {
      console.error('Error during file downloads:', error);
    }
  };
  
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleDocumentSelect = async (selectedOption) => {
    setSelectedDocument(selectedOption);
    setFileData(null);
    setErrorMessage('');

    try {
      const fileRef = ref(storage, selectedOption.value);
      const fileURL = await getDownloadURL(fileRef);
      const response = await axios.post(
        // 'http://127.0.0.1:5001/xtemplater-web-app/us-central1/processDocxFile',
        'https://us-central1-xtemplater-web-app.cloudfunctions.net/processDocxFile',
        { fileUrl: fileURL }
      );
      setFileData(response.data.parsedData);
    } catch (error) {
      console.error('Error processing selected document:', error);
      setErrorMessage('An error occurred while processing the selected document.');
    }
  };

  const handleInputChange = (name, value) => {
    setFormValues((prevState) => {
      const updatedFormValues = {
        ...prevState,
        [name]: value,
      };
  
      // Count completed fields
      const filledFieldsCount = Object.values(updatedFormValues).filter((val) => val !== "").length;
      setCompletedFields(filledFieldsCount);
  
      return updatedFormValues;
    });
  };

  const isSectionComplete = (section) => {
    return section.variables.every((variable) => formValues[variable.name]);
  };

  const isFormComplete = () => {
    return fileData.every((section) => isSectionComplete(section));
  };

  const goToPreviousSection = () => {
    setCurrentSectionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };


  console.log("form values", formValues)

  const goToNextSection = () => {
    setCurrentSectionIndex((prevIndex) => Math.min(prevIndex + 1, fileData.length - 1));
  };

  const getDescriptionForLanguage = () => {
    console.log("wagagag", additionalData);
  
    if (!additionalData?.descHuman) return '';
  
    const languageDescriptions = additionalData.descHuman;
    const selectedLang = selectedLanguage?.value;
  
    // Znajdź opis pasujący do wybranego języka
    const matchingLang = languageDescriptions.find(
      (lang) => lang.language === selectedLang
    );
  
    return matchingLang?.description || 'No description available for the selected language.';
  };
  

  const getTitleForLanguage = () => {
    console.log("wagagag2", additionalData?.descHuman);
  
    if (!additionalData?.descHuman) return '';
  
    const languageDescriptions = additionalData.descHuman;
    const selectedLang = selectedLanguage?.value;
  
    // Znajdź tytuł pasujący do wybranego języka
    const matchingLang = languageDescriptions.find(
      (lang) => lang.language === selectedLang
    );
  
    return matchingLang?.title || 'No title available for the selected language.';
  };
  



  const handleFileUpload = async () => {
    if (!file) return alert('Please select a file before uploading.');
  
    const storageRef = ref(storage, `uploads/${Date.now()}_${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setIsUploading(true);
        setUploadProgress(progress);
        console.log('Upload progress:', progress, '%');
      },
      (error) => {
        console.error('File upload error:', error);
        setIsUploading(false);
      },
      async () => {
        try {
          // Get the full path of the uploaded file
          const selectedFilePath = storageRef.fullPath;

          setFilePath(selectedFilePath);
  
          // Send the file path to the backend for processing
          const response = await axios.post(
            // 'http://127.0.0.1:5001/xtemplater-web-app/us-central1/processDocxFile',
            'https://us-central1-xtemplater-web-app.cloudfunctions.net/processDocxFile',
            { selectedFilePath },
            { headers: { 'Content-Type': 'application/json' } }
          );
  
          const result = response.data;
          console.log(result);
  
          // Extract languages from the response
          const parsedData = result.parsedData;
          if (parsedData && parsedData.length > 0) {
            const firstSection = parsedData[0];
            const languages = firstSection.sectionNames.map((langObj) => {
              const [key] = Object.keys(langObj);
              return { value: key, label: key.toUpperCase() };
            });
  
            setLanguageOptions(languages);
  
            // Set the first language as the default selectedLanguage
            if (languages.length > 0) {
              setSelectedLanguage(languages[0]);
            } else {
              setSelectedLanguage(null); // Fallback if no languages are available
            }
          }
  
          // Update file data with the parsed response
          setFileData(parsedData);
          setAdditionalData(response.data.additionalData);
  
          setMessage('File processed successfully!');
          setMessageColor('green');

          setIsUploadCollapsed(true);
        } catch (error) {
          console.error('Error processing file:', error);
          setMessage(`Error processing file: ${error.message}`);
          setMessageColor('red');
        } finally {
          setIsUploading(false);
          setUploadProgress(0);
        }
      }
    );
  };
  
  
  

  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Document Interpreter</h3>
      {errorMessage && <p style={styles.error}>{errorMessage}</p>}
  
      {/* Tabs for Existing and Upload */}
      <div style={styles.tabs}>
        <button
          onClick={() => setActiveTab('existing')}
          style={activeTab === 'existing' ? styles.activeTab : styles.tab}
        >
          Select Existing Document
        </button>
        <button
          onClick={() => setActiveTab('upload')}
          style={activeTab === 'upload' ? styles.activeTab : styles.tab}
        >
          Upload New Document
        </button>
      </div>
  
      {/* Existing Document Tab Content */}
      {activeTab === 'existing' && (
        <div style={styles.tabContent}>
          <label style={styles.label}>Select an Existing Document:</label>
          <Select
            options={uploadedDocuments}
            onChange={handleDocumentSelect}
            isClearable
            placeholder="Choose an uploaded document"
            styles={selectStyles}
          />
        </div>
      )}

      {/* Upload New Document Tab Content */}
      {activeTab === 'upload' && (
        <div style={styles.uploadContainer}>
          {/* Collapsible Section */}
          <h4
            style={{
              ...styles.subHeading,
              cursor: 'pointer', // Add pointer cursor to indicate clickability
              userSelect: 'none', // Prevent text selection
            }}
            onClick={() => setIsUploadCollapsed((prev) => !prev)} // Toggle collapse
          >
            {isUploadCollapsed ? "Expand Upload Section" : "Upload New Document"}
          </h4>

          {!isUploadCollapsed && (
            <>
              {/* Drag-and-Drop Area */}
              <div
                style={{
                  ...styles.dropZone,
                  ...(dragActive ? styles.dropZoneActive : {}),
                }}
                onDragEnter={handleDrag}
                onDragOver={handleDrag}
                onDragLeave={handleDrag}
                onDrop={handleDrop}
                onClick={() => document.getElementById('fileUpload').click()} // Trigger file input on click
              >
                <p style={styles.dropZoneText}>
                  Drag and drop a file here, or choose a file
                </p>
                <input
                  id="fileUpload"
                  type="file"
                  accept=".docx"
                  style={styles.fileInput}
                  onChange={handleFileChange}
                />
              </div>

              {/* Display Selected File */}
              {file && (
                <p style={styles.selectedFileText}>
                  Selected file: <strong>{file.name}</strong>
                </p>
              )}

              {/* Upload and Process Button */}
              <button
                onClick={handleFileUpload}
                disabled={!file || isUploading}
                style={{
                  ...styles.uploadButton,
                  ...(file && !isUploading ? {} : styles.uploadButtonDisabled),
                }}
              >
                {isUploading ? 'Uploading...' : 'Upload and Process'}
              </button>

              {/* Upload Progress */}
              {isUploading && (
                <div style={styles.progressContainer}>
                  <p style={styles.progressText}>Postęp: {uploadProgress.toFixed(2)}%</p>
                  <div style={styles.progressBar}>
                    <div style={{ ...styles.progressFill, width: `${uploadProgress}%` }} />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}

  
      {/* Message Alert */}
      {message && (
        <div
          style={{
            ...styles.alert,
            backgroundColor: messageColor === 'green' ? '#d4edda' : '#f8d7da',
            color: messageColor === 'green' ? '#155724' : '#721c24',
          }}
        >
          {message}
        </div>
      )}
  
      {/* File Data Display and Interaction */}
      {fileData && (
        <>
          <hr style={styles.separator} />
          <div>
            <div style={styles.languageSelectorContainer}>
              <label style={styles.label}>Language:</label>
              <Select
                options={languageOptions}
                value={selectedLanguage}
                onChange={setSelectedLanguage}
                placeholder="Select language"
                styles={selectStyles}
              />
            </div>
          
          <div>
            <p style={{ fontWeight: "bold" }}>{getTitleForLanguage()}</p>
            <p style={{textAlign: "justify", lineHeight: "1.3"}}>{getDescriptionForLanguage()}</p>
          </div>
          </div>
  
          <div style={styles.accordionContainer}>
{/* AI Instructions Accordion */}
<div style={styles.accordionItem}>
  <div
    style={styles.accordionHeader}
    onClick={() =>
      setExpandedSections((prevState) => ({
        ...prevState,
        aiInstructions: !prevState.aiInstructions,
      }))
    }
  >
    AI Instructions
    <span style={{ float: 'right' }}>
      {expandedSections.aiInstructions ? '▲' : '▼'}
    </span>
  </div>
  {expandedSections.aiInstructions && (
    <div style={styles.accordionContent}>
      <p style={{ textAlign: 'justify', lineHeight: '1.3' }}>
        {additionalData?.descAI?.description || 'No AI instructions available.'}
      </p>
    </div>
  )}
</div>


  <Modal
  isOpen={isModalOpen}
  onRequestClose={() => setIsModalOpen(false)}
  handleGenerate={handleGenerate}
  style={{
    ...modalStyles,
    overlay: {
      ...modalStyles.overlay,
      zIndex: 9999, // Ensure the modal appears above all elements
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparent overlay
    },
  }}
  contentLabel="Download Options"
>
  <h2 style={styles.modalTitle}>Select File Formats</h2>
  <div style={styles.optionsContainer}>
    <div style={styles.checkboxOption}>
      <input
        type="checkbox"
        value="docx"
        checked={selectedFormat.includes('docx')}
        onChange={(e) => handleCheckboxChange(e, 'docx')}
      />
      <label>DOCX</label>
    </div>
    <div style={styles.checkboxOption}>
      <input
        type="checkbox"
        value="html"
        checked={selectedFormat.includes('html')}
        onChange={(e) => handleCheckboxChange(e, 'html')}
      />
      <label>HTML</label>
    </div>
    <div style={styles.checkboxOption}>
      <input
        type="checkbox"
        value="pdf"
        checked={selectedFormat.includes('pdf')}
        onChange={(e) => handleCheckboxChange(e, 'pdf')}
      />
      <label>PDF</label>
    </div>
  </div>
  <div style={styles.checkboxOption}>
    <input
      type="checkbox"
      value="retainVariables"
      checked={retainVariables}
      onChange={() => setRetainVariables((prev) => !prev)}
    />
    <label>Retain inputted markings (bold and yellow)</label>
  </div>
  <button
    onClick={handleGenerate}
    style={{
      ...styles.downloadButton,
      backgroundColor: selectedFormat.length > 0 ? '#333' : '#999',
      cursor: selectedFormat.length > 0 ? 'pointer' : 'not-allowed',
    }}
    disabled={selectedFormat.length === 0}
  >
    Download
  </button>
  <button
    onClick={() => setIsModalOpen(false)}
    style={styles.closeButton}
  >
    Close
  </button>
</Modal>

{/* Tags Accordion */}
<div style={styles.accordionItem}>
  <div
    style={styles.accordionHeader}
    onClick={() =>
      setExpandedSections((prevState) => ({
        ...prevState,
        tags: !prevState.tags,
      }))
    }
  >
    Tags
    <span style={{ float: 'right' }}>
      {expandedSections.tags ? '▲' : '▼'}
    </span>
  </div>
  {expandedSections.tags && (
    <div style={styles.accordionContent}>
      <ul>
        {(additionalData?.tags || []).map((tag, index) => (
          <li key={index} style={{ marginBottom: '5px', lineHeight: '1.3' }}>
            {tag.name}
          </li>
        ))}
      </ul>
    </div>
  )}
</div>

</div>

          <div>

          </div>
          {/* Toggle between Form and Chatbot Modes */}
          <div style={styles.toggleButtonsContainer}>
            <button
              onClick={() => setInputMode('form')}
              style={{
                ...styles.toggleButton,
                ...(inputMode === 'form' && styles.activeToggleButton),
              }}
            >
              Form Mode
            </button>
            <button
              onClick={() => setInputMode('chatbot')}
              style={{
                ...styles.toggleButton,
                ...(inputMode === 'chatbot' && styles.activeToggleButton),
              }}
            >
              Chatbot Mode
            </button>
          </div>
  
          {/* Chatbot Mode */}
          {inputMode === 'chatbot' && (
            <div style={styles.chatbotContainer}>
              <h4 style={styles.subHeading}>Chatbot Mode</h4>
              <p>Engage in a conversation to fill out your document interactively with the chatbot.</p>
              <ChatbotInterface 
                fileData={fileData} 
                setFileData={setFileData}
                formValues={formValues} 
                handleInputChange={handleInputChange}
                languageOptions={languageOptions}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          )}
  
          {/* Form Mode */}
          {inputMode === 'form' && (
            <>
              <Form
                fileData={fileData}
                formValues={formValues}
                handleInputChange={handleInputChange}
                languageOptions={languageOptions}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                currentSectionIndex={currentSectionIndex}
                goToPreviousSection={goToPreviousSection}
                goToNextSection={goToNextSection}
                isFormComplete={isFormComplete}
                styles={styles}
                handleGenerate={handleGenerate}
                setIsModalOpen={setIsModalOpen}
              />
              <div style={styles.summaryBox}>
                <h4 style={{ margin: 0, paddig: 0 }}>Form Completion Status</h4>
                <div style={styles.progressContainer}>
                  <div style={styles.progressBarWrapper}>
                    <div style={{ ...styles.progressBar, width: `${progressPercentage}%` }} />
                  </div>
                  <span>{`Completion: ${Math.round(progressPercentage)}%`}</span>
                  <span>{`Estimated time remaining: ${Math.ceil(estimatedRemainingTime / 60)} min`}</span>
                </div>
                <ul style={styles.sectionStatusList}>
                  {fileData.map((section, index) => (
                    <li
                      key={index}
                      style={styles.sectionStatusItem}
                      onClick={() => setCurrentSectionIndex(index)}
                    >
                      <span style={isSectionComplete(section) ? styles.completeCheck : styles.incompleteCross}>
                        {isSectionComplete(section) ? '✔' : '✘'}
                      </span>
                      {section.sectionNames.find(name => name[selectedLanguage?.value])?.[selectedLanguage.value] || "Section"}
                    </li>
                  ))}
                </ul>
  
                {/* Final Submit Button */}
                <button
                  type="button"
                  style={{
                    ...styles.submitButton,
                    backgroundColor: isFormComplete() ? 'green' : 'gray',
                  }}
                  disabled={!isFormComplete()}
                  onClick={() => setIsModalOpen(true)}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
  
}

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    maxWidth: '400px',
    width: '100%',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

const styles = {
  container: {
    backgroundColor: 'rgb(250, 250, 251)',
    padding: '20px',
    maxWidth: '600px',
    margin: 'auto',
    borderRadius: '8px',
    boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.1)',
    marginTop: "3rem",
    marginBottom: '4rem',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  heading: {
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '20px',
    textAlign: 'center',
    fontSize: '1.75rem',
  },
  checkboxOption: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    gap: '10px',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  tabs: {
    display: 'flex',
    marginBottom: '20px',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  subHeading: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '15px',
    textAlign: 'center',
    cursor: 'pointer', // Indicate it can be clicked
    userSelect: 'none', // Prevent text selection
    transition: 'color 0.3s ease',
  },
  subHeadingHover: {
    color: '#555', // Change color on hover
  },
  tab: {
    flex: 1,
    padding: '12px',
    backgroundColor: '#d9d9d9',
    border: '1px solid #333',
    cursor: 'pointer',
    textAlign: 'center',
    color: '#333',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  },
  activeTab: {
    flex: 1,
    padding: '12px',
    backgroundColor: '#333',
    color: '#fff',
    border: '1px solid #333',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tabContent: {
    padding: '20px',
    border: '1px solid #333',
    borderRadius: '0 0 4px 4px',
    backgroundColor: '#fff',
  },
  uploadContainer: {
    padding: '20px',
    border: '1px solid #333',
    borderRadius: '4px',
    backgroundColor: '#fff',
  },
  subHeading: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '15px',
    textAlign: 'center',
  },
  dropZone: {
    border: '2px dashed #ccc',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer', // Indicate it's clickable
    transition: 'border-color 0.3s ease',
    backgroundColor: '#f9f9f9',
    position: 'relative',
  },
  
  accordionContent: {
    padding: '10px',
    backgroundColor: '#fff',
  },
  dropZoneActive: {
    borderColor: '#333',
    backgroundColor: '#e6e6e6',
  },
  dropZoneText: {
    fontSize: '1rem',
    color: '#555',
    margin: '0',
  },
  chooseFileLink: {
    color: '#333',
    textDecoration: 'underline',
    cursor: 'pointer', // Specific cursor style for link-like appearance
  },
  fileInput: {
    display: 'none', // Hidden but accessible for programmatic clicks
  },
  selectedFileText: {
    marginTop: '1rem',
    fontSize: '1rem',
    color: '#333',
    textAlign: 'center',
  },
  uploadButton: {
    padding: '10px',
    width: '100%',
    backgroundColor: '#333',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginTop: '2rem',
    transition: 'background-color 0.3s ease',
  },
  uploadButtonDisabled: {
    backgroundColor: '#999',
    cursor: 'not-allowed',
  },
  progressContainer: {
    marginTop: '10px',
    textAlign: 'left',
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    marginBottom: "1rem",
    fontSize: ".8rem"
  },
  progressBarWrapper: {
    width: '100%',
    height: '10px',
    backgroundColor: '#e0e0e0', // Light gray background
    borderRadius: '5px',
    overflow: 'hidden',
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#000', // Black fill for progress
    transition: 'width 0.3s ease', // Smooth transition for progress changes
  },
  progressText: {
    color: '#333',
    fontSize: '14px',
    marginBottom: '5px',
  },
  progressFill: {
    height: '100%',
    backgroundColor: '#333',
    transition: 'width 0.3s ease',
  },
  error: {
    color: 'red',
    textAlign: 'center',
    marginBottom: '15px',
    fontWeight: 'bold',
  },
  alert: {
    padding: '15px 20px',
    marginBottom: '20px',
    marginTop: "20px",
    borderRadius: '4px',
    fontWeight: 'bold',
    textAlign: 'center',
    border: '1px solid transparent',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  separator: {
    marginTop: '2rem',
    marginBottom: '2rem',
    border: 'none',
    borderTop: '2px solid #333', // Thicker and darker line
    opacity: '0.7', // Slight transparency for a softer look
  },
  toggleButtonsContainer: {
    display: 'flex',
    marginBottom: '20px',
    marginTop: "2rem",
    gap: '10px',
  },
  toggleButton: {
    flex: 1,
    padding: '8px 16px',
    border: '1px solid #333',
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#333',
    borderRadius: '4px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  },
  activeToggleButton: {
    backgroundColor: '#333',
    color: '#fff',
  },
  chatbotContainer: {
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.05)',
    marginBottom: '20px',
    border: "1px solid rgb(204, 204, 204)",
  },
  summaryBox: {
    position: 'fixed', // Stick to a fixed position
    bottom: '20px', // Distance from the bottom of the viewport
    right: '20px', // Distance from the right of the viewport
    width: '300px', // Define a fixed width for the box
    padding: '15px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    zIndex: 300, // Ensure it appears above other elements
  },
  radioOption: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  downloadButton: {
    padding: '10px 20px',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    marginTop: '15px',
    fontSize: '1rem',
    width: '100%',
    backgroundColor: '#000',
    cursor: 'pointer',
    textAlign: 'center',
  },
  closeButton: {
    padding: '10px',
    backgroundColor: '#ccc',
    border: 'none',
    borderRadius: '4px',
    marginTop: '10px',
    width: '100%',
    fontSize: '1rem',
    cursor: 'pointer',
  },
  sectionStatusList: {
    listStyle: 'none',
    padding: '0',
    margin: '0 0 15px 0',
  },
  sectionStatusItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    cursor: 'pointer',
  },
  completeCheck: {
    color: 'green',
    marginRight: '8px',
  },
  incompleteCross: {
    color: 'red',
    marginRight: '8px',
  },
  submitButton: {
    padding: '10px',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '100%',
    fontWeight: 'bold',
    backgroundColor: 'gray',
    transition: 'background-color 0.3s ease',
  },
  languageSelectorContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: "column",
    marginBottom: '1rem',
    width: "100%",
    gap: ".5rem"
  },
  accordionContainer: {
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    padding: '20px',
  },
  accordionContainer: {
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',

  },
  accordionItem: {
    marginBottom: '1rem',
    borderRadius: '4px',
    overflow: 'hidden',
    border: '1px solid #ddd',
    width: "100%",

  },
  accordionHeader: {
    padding: '10px',
    backgroundColor: '#eeeeee',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #333',
    borderRadius: '4px',
    boxShadow: 'none',
    backgroundColor: '#f9f9f9',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'rgb(240, 240, 240)' : '#fff',
    color: '#333',
  }),
};

export default DocumentInterpreter;
