import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

function ChatbotInterface({ fileData, handleInputChange, formValues,  selectedLanguage, setIsModalOpen}) {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});
  const [conversation, setConversation] = useState([
    { sender: 'bot', text: 'Hello! I’m here to help you fill out the form.' },
  ]);
  const [inputValue, setInputValue] = useState('');
  const [completedFields, setCompletedFields] = useState(0);
  const totalFields = fileData ? fileData.reduce((count, section) => count + section.variables.length, 0) : 0;
  const progressPercentage = (completedFields / totalFields) * 100;
  const [threadId, setThreadId] = useState(null);

  const [isFormComplete, setIsFormComplete] = useState(false);

  const handleToggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const handleSectionToggle = (index) => {
    setExpandedSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleChatInputChange = (event) => {
    setInputValue(event.target.value);
  };


  // Funkcja do zarządzania sekcjami
const getCurrentSection = (fileData, formValues) => {
  const currentSectionIndex = formValues.currentSectionIndex || 0;
  return fileData[currentSectionIndex];
};

 
const filterLanguages = (fileData) => {
    return fileData.map(section => {
        const hasEnglish = section.variables.some(variable => 
            variable.languages.some(lang => lang.language === 'en')
        );

        const filteredVariables = section.variables.map(variable => {
            const enLanguage = variable.languages.find(lang => lang.language === 'en');
            if (enLanguage) {
                return {
                    ...variable,
                    languages: [enLanguage]
                };
            } else {
                // Jeśli nie ma języka angielskiego, wybierz pierwszy dostępny
                return {
                    ...variable,
                    languages: [variable.languages[0]]
                };
            }
        });

        return {
            ...section,
            variables: filteredVariables
        };
    });
};



  const handleSendMessage = async () => {
    if (inputValue.trim() === "") return;

    // Dodaj wiadomość użytkownika do rozmowy
    setConversation((prev) => [...prev, { sender: "user", text: inputValue }]);

    try {
        const response = await axios.post(
            // "http://127.0.0.1:5001/xtemplater-web-app/us-central1/talkWithDocMate",
            "https://us-central1-xtemplater-web-app.cloudfunctions.net/talkWithDocMate",
            {
                fileData, // Struktura formularza
                formValues, // Aktualne wartości formularza
                userMessage: inputValue, // Wiadomość użytkownika
                conversation: conversation, // Przekazanie historii rozmowy
                threadId: threadId, // Identyfikator wą
            }
        );

        console.log("Uwaga moje response", response);

        // Sprawdź, czy odpowiedź zawiera dane
        if (response.data) {
            const { response: assistantResponse, threadId: newThreadId } = response.data;

            // Zaktualizuj threadId, jeśli nowy został zwrócony
            if (newThreadId && newThreadId !== threadId) {
                setThreadId(newThreadId);
            }

            if (newThreadId === "cleared") {
              setIsFormComplete(true);
            }

            const botMessage = assistantResponse.text || "No response received";
            setConversation((prev) => [...prev, { sender: "bot", text: botMessage }]);

            // Aktualizuj formValues za pomocą `assistantResponse.json`
            if (assistantResponse.json) {
                const updatedJson = assistantResponse.json;

                // Iteruj przez nowe dane i wywołaj handleInputChange dla każdego klucza
                Object.entries(updatedJson).forEach(([key, value]) => {
                    if (value !== undefined && value !== null) {
                        handleInputChange(key, value); // Aktualizuj dane
                    }
                });
            }
        } else {
            throw new Error("Empty response from server.");
        }
    } catch (error) {
        console.error("Error communicating with API:", error);
        setConversation((prev) => [
            ...prev,
            { sender: "bot", text: "Sorry, there was an error. Please try again." },
        ]);
    }

    // Wyczyść pole tekstowe
    setInputValue("");
};

console.log("uwaga file data",fileData);
  

  useEffect(() => {
    const filledFields = Object.values(formValues).filter(Boolean).length;
    setCompletedFields(filledFields);
  }, [formValues]);

  const isSectionComplete = (section) => section.variables.every((variable) => formValues[variable.name]);

  // SVG components for expand and collapse icons
  const ExpandIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={styles.smallIcon}>
      <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
    </svg>
  );

  const CollapseIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={styles.smallIcon}>
      <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/>
    </svg>
  );

  console.log(formValues, fileData);

  return (
    <div style={styles.container}>
    

      {/* Accordion for Simplified Form */}
      <div style={styles.accordion}>
  <div style={styles.accordionHeader} onClick={handleToggleForm}>
    <h4 style={styles.accordionTitle}>My responses</h4>
    {isFormOpen ? <CollapseIcon /> : <ExpandIcon />}
  </div>
  
  {isFormOpen && (
    <div style={styles.formContent}>
      {fileData.map((section, sectionIndex) => {
        const isLastSection = sectionIndex === fileData.length - 1;

        return (
          <div
            key={sectionIndex}
            style={{
              ...styles.sectionContainer,
              marginBottom: isLastSection ? '0' : '10px', // Conditionally remove margin for the last section
            }}
          >
            <div
              style={styles.sectionHeader}
              onClick={() => handleSectionToggle(sectionIndex)}
            >
              <span style={isSectionComplete(section) ? styles.completeCheck : styles.incompleteCross}>
                {isSectionComplete(section) ? '✔' : '✘'}
              </span>
              <h5 style={styles.sectionTitle}>
                {section.sectionNames.find((name) => name[selectedLanguage.value])?.[selectedLanguage.value] || 'Section'}
              </h5>
              <span>{expandedSections[sectionIndex] ? <CollapseIcon /> : <ExpandIcon />}</span>
            </div>
            {expandedSections[sectionIndex] && (
              <div style={styles.sectionContent}>
                {section.variables.map((variable) => {
                  const languageData = variable.languages.find((lang) => lang.language === selectedLanguage.value);

                  return (
                    <div key={variable.name} style={styles.formField}>
                      <label style={styles.formLabel}>{languageData ? languageData.fieldName : variable.name}</label>
                      <input
                        type="text"
                        value={formValues[variable.name] || ''}
                        placeholder={languageData?.placeholder || ''}
                        onChange={(e) => handleInputChange(variable.name, e.target.value)}
                        style={styles.formInput}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  )}
  <div style={styles.summaryBox}>
        {/* <h4>Form Completion Status</h4> */}
        <div style={styles.progressContainer}>
          <div style={styles.progressBarWrapper}>
            <div style={{ ...styles.progressBar, width: `${progressPercentage}%` }}></div>
          </div>
          <span style={{display: "block", marginTop: ".3rem", fontSize: ".8rem"}}>{`Completion: ${Math.round(progressPercentage)}%`}</span>
        </div>
      </div>
</div>


      {/* Chatbot Interface */}
      <div style={styles.chatContainer}>
        <div style={styles.chatWindow}>
          {conversation.map((msg, index) => (
            <div
              key={index}
              style={msg.sender === 'bot' ? styles.botMessage : styles.userMessage}
            >
              {msg.text}
            </div>
          ))}
        </div>
        <div style={styles.inputContainer}>
          <textarea
            value={inputValue}
            onChange={handleChatInputChange}
            placeholder="Type a message..."
            style={styles.chatInput}
            rows="3"
          />
           {isFormComplete ? (
        <button
          type="button"
          style={{
            ...styles.submitButton,
            backgroundColor: isFormComplete() ? 'green' : 'gray',
          }}
          disabled={!isFormComplete()}
          onClick={() => setIsModalOpen(true)}
        >
          Submit
        </button>
      ) : (
        <button
          type="button"
          style={{
            ...styles.sendButton,
            backgroundColor: inputValue.trim() !== "" ? 'black' : 'gray',
          }}
          disabled={inputValue.trim() === ""}
          onClick={handleSendMessage}
        >
          Send
        </button>
      )}
        </div>
      </div>

      
    </div>
  );
}

const styles = {
  container: {
    margin: 'auto',
    backgroundColor: 'rgb(250, 250, 251)',
    borderRadius: '8px',
    width: "100%",
    
  },
  languageSelector: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  label: {
    marginRight: '10px',
    fontWeight: 'bold',
  },
  accordion: {
    borderLeft: '1px solid #333',
    borderRight: '1px solid #333',
    borderTop: '1px solid #333',
  },
  accordionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#f0f0f0',
    cursor: 'pointer',
  },
  accordionTitle: {
    margin: 0,
    color: '#333',
    fontWeight: 'bold',
  },
  smallIcon: {
    width: '16px',
    height: '16px',
    fill: '#333',
  },
  formContent: {

    backgroundColor: '#fff',
  },
  sectionContainer: {
    marginBottom: '10px',
  },
  submitButton: {
    padding: '10px',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    width: '100%',
    fontWeight: 'bold',
    backgroundColor: 'gray',
    transition: 'background-color 0.3s ease',
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#e0e0e0',
    cursor: 'pointer',
    paddingInline: "10px"
  },
  sectionTitle: {
    flex: 1,
    margin: 0, // Removes default margins around the h5 tag
    padding: '5px 10px', // Adjust padding for a balanced look
    fontWeight: 'bold',
    color: '#333',

    lineHeight: '1.2', // Reduces line height for more compact spacing
  },
  sectionContent: {
    padding: '10px',
    backgroundColor: '#fff',
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  formLabel: {
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  formInput: {
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #333',
    fontSize: '1em', // Adjust font size as needed
  },
  chatContainer: {
    border: '1px solid #333',

    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',

  },
  chatWindow: {
    padding: '10px',
    minHeight: "300px",
    overflowY: 'auto',
    backgroundColor: '#fafafa',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  botMessage: {
    backgroundColor: '#f0f0f0',
    color: '#333',
    padding: '8px',
    borderRadius: '4px',
    alignSelf: 'flex-start',
  },
  userMessage: {
    backgroundColor: '#333',
    color: '#fff',
    padding: '8px',
    borderRadius: '4px',
    alignSelf: 'flex-end',
  },
  inputContainer: {
    display: 'flex',
    borderTop: '1px solid #333',
  },
  chatInput: {
    flex: 1,
    padding: '10px',
    border: 'none',
    outline: 'none',
    resize: 'none',
    fontFamily: 'Arial, sans-serif', 
  },
  sendButton: {
    padding: '10px 20px',
    backgroundColor: '#333',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  summaryBox: {
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingInline: "10px",
    backgroundColor: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
  progressContainer: {
    width: '100%',
    marginTop: '10px',
  },
  progressBarWrapper: {
    width: '100%',
    height: '8px',
    backgroundColor: '#ddd',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#4caf50',
    transition: 'width 0.3s ease',
  },
  completeCheck: { color: 'green' },
  incompleteCross: { color: 'red' },
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #333',
    borderRadius: '4px',
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'rgb(240, 240, 240)' : 'white',
    color: '#333',
  }),
};

export default ChatbotInterface;
