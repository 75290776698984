// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// Your web app's Firebase configuration
// Your web app's Firebase configuration

const firebaseConfig = {

    apiKey: "AIzaSyA4s2HVobtBKwtA6RQ4A9FTgIZx0BQH2lA",
  
    authDomain: "xtemplater-web-app.firebaseapp.com",
  
    projectId: "xtemplater-web-app",
  
    storageBucket: "xtemplater-web-app.firebasestorage.app",
  
    messagingSenderId: "1082228792738",
  
    appId: "1:1082228792738:web:eed02d76c1fbae0a3b66fb"
  
  };
  

  const app = initializeApp(firebaseConfig);
  export const auth = getAuth(app);
  export const db = getFirestore(app);
  export const storage = getStorage(app);
