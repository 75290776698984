import React, { useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from './firebaseConfig';
import Select from 'react-select';

function DocumentUploader({ onUploadSuccess }) {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [documentType, setDocumentType] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);



  const [dragActive, setDragActive] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };


  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setSelectedFile(e.dataTransfer.files[0]);
    }
  };

const handleLanguageChange = (e) => {
  // Pobierz wybrane wartości z selecta
  const options = Array.from(e.target.selectedOptions);
  setSelectedLanguages(options.map(option => option.value));
};



const handleFileUpload = async () => {
  if (!selectedFile) return alert('Wybierz plik przed przesłaniem.');

  setIsLoading(true); // Start loading indicator
  const storageRef = ref(storage, `uploads/${Date.now()}_${selectedFile.name}`);
  const uploadTask = uploadBytesResumable(storageRef, selectedFile);

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setUploadProgress(progress);
      console.log('Postęp przesyłania:', progress, '%');
    },
    (error) => {
      console.error('Błąd przesyłania pliku:', error);
      setIsLoading(false); // Stop loading indicator on error
    },
    async () => {
      const selectedFilePath = storageRef.fullPath;
      try {
       
        // http://127.0.0.1:5001/xtemplater-web-app/us-central1/templateDocxFile
        const response = await fetch('https://us-central1-xtemplater-web-app.cloudfunctions.net/templateDocxFile', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ selectedFilePath, selectedLanguages, documentType }),
        });

        const result = await response.json();

        console.log(result);
        const downloadUrl = result.downloadUrl;

        // Trigger download programmatically
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = selectedFile.name; // Optional: Specify the file name for download
        link.click();
        console.log('Plik został pobrany.');
        if (onUploadSuccess) onUploadSuccess(downloadUrl);
      } catch (error) {
        console.error('Błąd podczas wywołania funkcji kopiującej:', error);
      } finally {
        setIsLoading(false); // Stop loading indicator
      }
    }
  );
};




  
  const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Spanish' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'it', label: 'Italian' },
    { value: 'pt', label: 'Portuguese' },
    { value: 'ru', label: 'Russian' },
    { value: 'zh', label: 'Chinese' },
    { value: 'ja', label: 'Japanese' },
    { value: 'ko', label: 'Korean' },
    { value: 'ar', label: 'Arabic' },
    { value: 'pl', label: 'Polish' },
    { value: 'hi', label: 'Hindi' },
    { value: 'nl', label: 'Dutch' },
    { value: 'sv', label: 'Swedish' },
  ];

  return (
    <div style={styles.container}>
      <h3 style={styles.heading}>Upload Document</h3>
      <div
  style={{
    ...styles.dropZone,
    ...(dragActive ? styles.dropZoneActive : {}),
  }}
  onDragEnter={handleDrag}
  onDragOver={handleDrag}
  onDragLeave={handleDrag}
  onDrop={handleDrop}
  onClick={() => document.getElementById('fileUpload').click()} // Trigger file input on click
>
  <p style={styles.dropZoneText}>
    Drag and drop a file here, or choose a file
  </p>
  <input
    id="fileUpload"
    type="file"
    style={styles.fileInput}
    onChange={handleFileChange}
  />
</div>


      {selectedFile && (
        <p style={styles.selectedFileText}>
          Selected file: <strong>{selectedFile.name}</strong>
        </p>
      )}


      
      {selectedFile && (
        <div style={{ ...styles.transitionContainer, opacity: selectedFile ? 1 : 0 }}>
          <label style={styles.label}>Document Type:</label>
          <Select
            options={[
              { value: 'dotted', label: 'Dotted document (...)' },
              { value: 'realLife', label: 'Real Life document with data' },
              { value: 'ocr', label: 'OCR document' },
            ]}
            onChange={(option) => setDocumentType(option)}
            placeholder="Select document type"
            styles={selectStyles}
          />

          <label style={{ ...styles.label, marginTop: '15px' }}>Select document languages:</label>
          <Select
            options={languageOptions}
            isMulti
            onChange={(options) => setSelectedLanguages(options)}
            placeholder="Choose countries"
            styles={selectStyles}
          />
        </div>
      )}

      {isLoading && (
       <div style={styles.loadingOverlay}>
       <div style={styles.spinner}></div>
       <div style={styles.loadingMessage}>
         <h4 style={styles.loadingTitle}>We’re templating your file</h4>
         <p style={styles.loadingText}>
      This may take a couple of minutes depending on your file size. Thanks for your patience!
    </p>
       </div>
     </div>
     
      )}

<style>
  {`
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `}
</style>

      <button onClick={handleFileUpload} disabled={!selectedFile} style={styles.uploadButton}>Upload and Process</button>

      {uploadProgress > 0 && (
        <div style={styles.progressContainer}>
          <p style={styles.progressText}>Uploading: {uploadProgress.toFixed(2)}%</p>
          <div style={styles.progressBar}>
            <div style={{ ...styles.progressFill, width: `${uploadProgress}%` }} />
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: 'rgb(250, 250, 251)',
    padding: '20px',
    maxWidth: '500px',
    margin: 'auto',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '4rem',
  },
  heading: {
    color: '#333',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  selectedFileInput: {
    width: '96.5%',
    padding: '8px',
    marginBottom: '10px',
    border: '1px solid #333',
    borderRadius: '4px',
  },
  label: {
    marginBottom: '.3rem',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: ".5rem",
    display: "block"
  },
  uploadButtonReversed: {
    padding: '10px',
    width: '100%',
    backgroundColor: '#fff',
    color: '#333',
    border: '2px solid #333',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginTop: '2rem',
    transition: 'background-color 0.3s ease, color 0.3s ease',
  },
  loadingOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  loadingText: {
    fontSize: '1.5rem',
    color: '#333',
    fontWeight: 'bold',
  },  
  uploadButton: {
    padding: '10px',
    width: '100%',
    backgroundColor: '#333',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginTop: '2rem',
  },
  progressContainer: {
    marginTop: '15px',
  },
  progressText: {
    color: '#333',
    fontSize: '14px',
    marginBottom: '5px',
  },
  progressBar: {
    width: '100%',
    height: '10px',
    backgroundColor: '#e0e0e0',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  progressFill: {
    height: '100%',
    backgroundColor: '#333',
  },
  transitionContainer: {
    transition: 'opacity 0.3s ease-in-out',
  },
  fileInputWrapper: {
    position: "relative",
    display: "inline-block",
    width: "100%",
    marginBottom: "1rem",
  },
  fileInput: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 0,
    cursor: "pointer",
  },
  loadingOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    animation: 'fadeIn 0.5s ease-in-out', // Smooth fade-in
  },
  loadingText: {
    fontSize: '1.5rem',
    color: '#333',
    fontWeight: 'bold',


  },
  spinner: {
    width: '50px',
    height: '50px',
    border: '6px solid #ccc',
    borderTop: '6px solid #333',
    borderRadius: '50%',
    animation: 'spin 3s linear infinite',
    marginTop: "15rem"
  },
  fileInputLabel: {
    display: "block",
    padding: "10px 15px",
    backgroundColor: "#fff",
    color: "#000",
    border: "1px solid #000",
    textAlign: "center",
    borderRadius: "4px",
    cursor: "pointer",
    fontWeight: "600",
    fontSize: "1rem",
    transition: "background-color 0.3s ease",
  },
  fileInputLabelHover: {
    backgroundColor: "#444",
  },
  dropZone: {
    border: '2px dashed #ccc',
    borderRadius: '8px',
    padding: '20px',
    cursor: 'pointer',
    transition: 'border-color 0.3s ease',
  },
  dropZoneActive: {
    borderColor: '#333',
  },
  dropZoneText: {
    fontSize: '1rem',
    color: '#555',
  },
  chooseFileLink: {
    color: '#333',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  fileInput: {
    display: 'none',
  },
  selectedFileText: {
    marginTop: '1rem',
    fontSize: '1rem',
    color: '#333',
  },
  loadingOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slightly brighter overlay
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    animation: 'fadeIn 0.5s ease-in-out',
  },
  spinner: {
    width: '60px',
    height: '60px',
    border: '8px solid #ccc',
    borderTop: '8px solid #333',
    borderRadius: '50%',
    animation: 'spin 1.2s linear infinite',
    marginBottom: '20px',
    marginTop: "18rem"
  },
  loadingMessage: {
    textAlign: 'center',
    maxWidth: '400px',
    padding: '0 20px',
  },
  loadingTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '10px',
  },
  loadingText: {
    fontSize: '1rem',
    color: '#555',
    lineHeight: '1.5',
  },
};

const selectStyles = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #333',
    borderRadius: '4px',
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'rgb(240, 240, 240)' : 'white',
    color: '#333',
  }),
};

export default DocumentUploader;
