import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebaseConfig"; // Import bezpośredni
import Navbar from './Navbar';
import DocumentTemplater from './DocumentTemplater';
import DocumentInterpreter from './DocumentInterpreter';
import Login from "./Login";
import MyDocuments from './MyDocuments';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [activeTab, setActiveTab] = useState('templater');
  const [uploadedFileData, setUploadedFileData] = useState(null);

  useEffect(() => {
    // Ustaw nasłuch na stan użytkownika
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User logged in:", user);
        setCurrentUser(user);
      } else {
        console.log("No user is logged in.");
        setCurrentUser(null);
      }
    });


    return () => unsubscribe(); // Odpinanie listenera przy odmontowaniu
  }, []);

  console.log("komo", activeTab)

  return (
    <Router>
      {currentUser && (
        <Navbar activeTab={activeTab} setActiveTab={setActiveTab} />
      )}
      <div style={{ backgroundColor: '#FAFAFB', minHeight: "100vh", overflowX: "hidden" }}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              currentUser ? (
                <>
                  {activeTab === 'templater' && (
                    <DocumentTemplater onUploadSuccess={(data) => setUploadedFileData(data)} />
                  )}
                  {activeTab === 'interpreter' && (
                    <DocumentInterpreter fileData={uploadedFileData} />
                  )}
                      {activeTab === 'documents' && (
                    // <MyDocuments />
                    <div>
                      <p>Site in rebuild</p>
                    </div>
                  )}
                </>
              ) : (
                <Login />
              )
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
